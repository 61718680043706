import item_yes from "assets/images/Quiz/quiz_yes.svg";
import item_no from "assets/images/Quiz/quiz_no.svg";

export const questions = [
  {
    questionText: "Already have a product?",
    questionOption: [
      {
        answerText: "Yes, we need a revision",
        className: "item_yes",
        quizImg: item_yes,
      },
      {
        answerText: "No, we need to start from scratch",
        className: "item_no",
        quizImg: item_no,
      },
    ],
  },
  {
    questionText: "Select products:",
    questionOption: [
      {
        answerText: "Website",
        type: "checkbox",
        name: "product",
        value: "Сайт",
      },
      {
        answerText: "Mobile app",
        type: "checkbox",
        name: "product",
        value: "Мобильное приложение",
      },
      {
        answerText: "Design",
        type: "checkbox",
        name: "product",
        value: "Дизайн",
      },
      {
        answerText: "I don't know, I need a consultation",
        type: "checkbox",
        name: "product",
        value: "Не знаю, нужна консультация",
        checked: "true",
      },
    ],
  },
  {
    questionText: "What is the project budget?",
    questionOption: [
      {
        answerText: "До 100’000 ₽",
        type: "radio",
        name: "budget",
        value: "До 100’000 ₽",
      },
      {
        answerText: "100’000–250’000 ₽",
        type: "radio",
        name: "budget",
        value: "100’000–250’000 ₽",
      },
      {
        answerText: "250’000–500’000 ₽",
        type: "radio",
        name: "budget",
        value: "250’000–500’000 ₽",
      },
      {
        answerText: "500’000–1’000’000 ₽",
        type: "radio",
        name: "budget",
        value: "500’000–1’000’000 ₽",
      },
      {
        answerText: "Более 1’000’000 ₽",
        type: "radio",
        name: "budget",
        value: "Более 1’000’000 ₽",
      },
      {
        answerText: "The budget is not defined",
        type: "radio",
        name: "budget",
        value: "Бюджет не определён",
        checked: "true",
      },
    ],
  },
  {
    questionOption: [
      {
        answerText: "Name or organization",
        type: "text",
        name: "name",
        placeholder: "Иван, ООО “Иванов”",
      },
      {
        answerText: "Email",
        type: "email",
        name: "email",
        placeholder: "ivan@innovide.ru",
      },
      {
        answerText: "Тelephone",
        type: "text",
        name: "tel",
        placeholder: "+7-297-934-33-44",
      },
    ],
  },
];
