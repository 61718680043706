import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import styles from "./Button.module.scss";
import { GetLanguage } from "hook/GetLanguage";

const Button = ({ classes, active }) => {
  const { lang } = GetLanguage();

  const [text, setText] = useState("Submit project");

  useEffect(() => {
    if (lang === "en") {
      setText("Submit project");
    } else {
      setText("مناقشة المشروع");
    }
  }, [lang]);

  return (
    <div
      className={`${styles.center} ${classes?.join(" ")} ${
        active ? "fadeIn" : ""
      }`}
    >
      <a href="#get_started" className={styles.link}>
        <button className={styles.button} type="button">
          {text}
        </button>
      </a>
    </div>
  );
};

export default Button;
