import Button from "components/components/Button/Button";
import React, { useEffect, useState } from "react";
import styles from "./Main.module.scss";
import ReactTypingEffect from "react-typing-effect";
import bannerEn from "../../../language/en/banner.json";
import bannerAr from "../../../language/arab/banner.json";
import { GetLanguage } from "hook/GetLanguage";

const Main = () => {
  const { lang } = GetLanguage();
  const [myBan, setMyBan] = useState(bannerEn);

  const chg = () => {
    if (lang === "en") {
      setMyBan(bannerEn);
    } else {
      setMyBan(bannerAr);
    }
  };

  useEffect(() => {
    chg();
  });

  return (
    <div className="container">
      <section className={`${styles.main} section_padding-disabled`}>
        <div className={styles.content}>
          <div className={`title ${styles.center}`}>
            <h1>
              {myBan[0]}{" "}
              <span className={styles.blue}>
                <ReactTypingEffect
                  text={[myBan[1], myBan[2], myBan[3]]}
                  // cursorRenderer={cursor => <h1>{cursor}</h1>}
                  speed={50}
                  eraseSpeed={50}
                  typingDelay={1000}
                />
              </span>
              <br />
              {myBan[4]}
            </h1>
          </div>
          <Button />
        </div>
      </section>
    </div>
  );
};

export default Main;
