import React, { useState } from "react";
import styles from "./Services.module.scss";
import { services, servicesTitle } from "data/Services/Services";
import arrow_down from "assets/images/arrow_down.svg";
import Button from "components/components/Button/Button";

const Mobile = ({ activeAnimBlock, mobile, servicesData }) => {
  const [active, setActive] = useState(false);

  console.log(servicesData);

  let content;
  const handleClick = (e) => {
    if (content !== e.target.nextElementSibling && content) {
      content.classList.remove(styles.active);
    }
    e.target.nextElementSibling.classList.toggle(styles.active);
    // e.target.getElementById('arrow_down').classList.toggle(styles.rotate_active)
    content = e.target.nextElementSibling;
  };

  return (
    <div className={mobile ? "" : "hidden"}>
      <ul
        className={`animated animatedFadeInUp ${
          activeAnimBlock ? "fadeInUp" : ""
        }`}
      >
        {servicesData.map((item, idx) => (
          <li key={`mobile-services-${idx + 1}`} className={styles.list}>
            <div className={styles.item} onClick={handleClick}>
              <ul className={styles["title-list"]}>
                <li>{item.mainTitle}</li>
              </ul>
              <img
                id="arrow_down"
                src={arrow_down}
                alt="arrow_down"
                className={styles.rotate}
              />
            </div>
            <div className={`${styles.content} ${styles.anim}`}>
              <img
                className={styles["img_mobile"]}
                src={services[idx].img}
                alt={`service-mobile-${idx + 1}`}
              />
              <div className="wrapper">
                <p className={styles.paragraph_mobile}>
                  {servicesData[idx].title}
                </p>
                <ul className={styles["list_mobile"]}>
                  {servicesData[idx].services.map((itemList, idxList) => (
                    <li key={`services-mobile-${idx + 1}-list-${idxList + 1}`}>
                      {itemList}
                    </li>
                  ))}
                </ul>
                <Button />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Mobile;
