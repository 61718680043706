import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/scss";
import "swiper/scss/pagination";
import styles from "./Services.module.scss";
import { Images } from "data/Services/Services";
import { Waypoint } from "react-waypoint";
import Button from "components/components/Button/Button";
import servicesAr from "../../../language/arab/service.json";
import { GetLanguage } from "hook/GetLanguage";

const Main = ({
  activeAnimBlock,
  activeAnimButton,
  setActiveAnimButton,
  mobile,
  servicesData,
}) => {
  const { lang } = GetLanguage();

  return (
    <div className={mobile ? "hidden" : ""}>
      <div className={styles["services-swiper"]}>
        <div
          id="swiper-pagination"
          className={`animated animatedFadeInUp ${
            activeAnimBlock ? "fadeInUp" : ""
          }`}
        ></div>
        {
          <Swiper
            className={`${styles.swiper} animated animatedFadeInUp ${
              activeAnimBlock ? "fadeInUp" : ""
            }`}
            modules={[Autoplay, Pagination]}
            wrapperClass={styles["swiper-wrapper"]}
            spaceBetween={0}
            slidesPerView={1}
            direction="vertical"
            pagination={{
              el: "#swiper-pagination",
              type: "bullets",
              clickable: true,
              renderBullet: function (idx, className) {
                return (
                  '<div class="' +
                  className +
                  '"><ul><li class=' +
                  " " +
                  ">" +
                  servicesData[idx].mainTitle +
                  "</li></ul></div>"
                );
              },
            }}
            autoplay={{ delay: 7500, disableOnInteraction: false }}
          >
            {servicesData.map((item, idx) => (
              <SwiperSlide
                key={`slide-services-${idx + 1}`}
                className={styles["swiper-slide"]}
              >
                <img
                  className={styles.img}
                  src={Images[idx].img}
                  alt={`service-${idx + 1}`}
                />
                <div className="wrapper">
                  <p className={styles.paragraph}>{item.title}</p>
                  <ul>
                    {item.services.map((itemList, idxList) => (
                      <li key={`slide-services-${idx + 1}-list-${idxList + 1}`}>
                        {itemList}
                      </li>
                    ))}
                  </ul>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        }
      </div>
      <Waypoint onEnter={() => setActiveAnimButton(true)} bottomOffset={200} />
      <Button
        classes={["animated", "animatedFadeIn"]}
        active={activeAnimButton}
      />
    </div>
  );
};

export default Main;
