import React, { useEffect, useState } from "react";
import styles from "./Benefits.module.scss";
import Pic1 from "assets/images/Benefits/benefit-1.png";
import Pic2 from "assets/images/Benefits/benefit-2.png";
import Pic3 from "assets/images/Benefits/benefit-3.png";
import { Waypoint } from "react-waypoint";
import dataEn from "../../../language/en/benefit.json";
import dataAr from "../../../language/arab/benefit.json";
import { GetLanguage } from "hook/GetLanguage";

const Benefits = () => {
  const [activeAnim, setActiveAnim] = useState(false);
  const [activeAnimOne, setActiveAnimOne] = useState(false);
  const [activeAnimTwo, setActiveAnimTwo] = useState(false);
  const [activeAnimThree, setActiveAnimThree] = useState(false);

  const { lang } = GetLanguage();
  const [benefitData, setBenefitData] = useState(dataEn);

  const chg = () => {
    if (lang === "en") {
      setBenefitData(dataEn);
    } else {
      setBenefitData(dataAr);
    }
  };

  useEffect(() => {
    chg();
  });

  return (
    <div className="container">
      <div
        className={`vl-wrapper vl-item ${activeAnim ? "active" : false}`}
      ></div>
      <section className={styles.benefits} id="benefits">
        <div className="title">
          <Waypoint onEnter={() => setActiveAnim(true)} bottomOffset={200} />
          <h3 className={`title-location ${activeAnim ? "active" : false}`}>
            {benefitData?.first[0]}
          </h3>
          <h1
            className={`animated animatedFadeInUp ${
              activeAnim ? "fadeInUp" : false
            }`}
          >
            {benefitData?.first[1]}
          </h1>
        </div>
        <ul className={styles["list-benefits"]}>
          <Waypoint onEnter={() => setActiveAnimOne(true)} bottomOffset={200} />
          <li
            className={`${styles.item} ${
              styles["item_flex-start"]
            } animated animatedFadeInLeft ${
              activeAnimOne ? "fadeInLeft" : false
            }`}
          >
            <div className={styles.img}>
              <img src={Pic1} alt="Pic-1" />
            </div>
            <div className="wrapper">
              <h3>{benefitData?.second[0]}</h3>
              <p>{benefitData?.second[1]}</p>
            </div>
          </li>
          <Waypoint onEnter={() => setActiveAnimTwo(true)} bottomOffset={200} />
          <li
            className={`${styles.item} ${
              styles["item_flex-end"]
            } animated animatedFadeInRight ${
              activeAnimTwo ? "fadeInRight" : false
            }`}
          >
            <div className={styles.img}>
              <img src={Pic2} alt="Pic-2" />
            </div>
            <div className="wrapper">
              <h3>{benefitData?.third[0]}</h3>
              <p>{benefitData?.third[1]}</p>
            </div>
          </li>
          <Waypoint
            onEnter={() => setActiveAnimThree(true)}
            bottomOffset={200}
          />
          <li
            className={`${styles.item} ${
              styles["item_flex-start"]
            } animated animatedFadeInLeft ${
              activeAnimThree ? "fadeInLeft" : false
            }`}
          >
            <div className={styles.img}>
              <img src={Pic3} alt="Pic-3" />
            </div>
            <div className="wrapper">
              <h3>{benefitData?.four[0]}</h3>
              <p>{benefitData?.four[1]}</p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Benefits;
