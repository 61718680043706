import React, { useState, useEffect } from "react";
import logo from "assets/images/logo_header.svg";
import styles from "./Header.module.scss";
import Mobile from "./Mobile";
import { GetLanguage } from "hook/GetLanguage";
import menuData from "../../../language/en/menu.json";
import menuArab from "../../../language/arab/menu.json";

const Header = ({ visible, setVisible }) => {
  const [direction, setDirection] = useState("top");
  let oldScrollY = 0;
  const controlDirection = () => {
    let scrolled = window.scrollY;
    if (scrolled > oldScrollY && scrolled > 100) {
      setDirection("down");
    }
    if (scrolled < oldScrollY && scrolled > 300) {
      setDirection("up");
    }
    if (scrolled == 0) {
      setDirection("top");
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", controlDirection);
    return () => {
      window.removeEventListener("scroll", controlDirection);
    };
  }, []);

  const { lang, changeLng } = GetLanguage();

  return (
    <header
      className={`${styles.header} ${
        direction === "down" ? `${styles.out}` : ""
      } ${direction === "up" ? `${styles.fixed}` : ""} ${
        visible ? styles["fixed_disabled"] : ""
      }`}
    >
      <div className={styles.container}>
        <a href="#" className={styles.link}>
          <img className={styles.logo} src={logo} alt="logo" />
        </a>
        <ul className={styles.nav}>
          {lang === "en" &&
            menuData.map((item, idx) => (
              <li key={`menu item ${idx}`} className={styles["list-item"]}>
                <a href={item.link} className={styles.link}>
                  {item.title}
                </a>
              </li>
            ))}
          {lang === "arb" &&
            menuArab.map((item, idx) => (
              <li key={`menu item ${idx}`} className={styles["list-item"]}>
                <a href={item.link} className={styles.link}>
                  {item.title}
                </a>
              </li>
            ))}
          <li className={`${styles.langs} ${styles["list-item"]}`}>
            <div>
              <button
                style={
                  lang === "en"
                    ? { backgroundColor: "#455ce9", color: "#fff" }
                    : {}
                }
                href="#"
                className="buttonEn"
                onClick={() => lang !== "en" && changeLng("en")}
              >
                EN
              </button>
              <button
                style={
                  lang === "arb"
                    ? { backgroundColor: "#455ce9", color: "#fff" }
                    : {}
                }
                href="#"
                className="buttonRu"
                onClick={() => lang !== "arb" && changeLng("arb")}
              >
                AR
              </button>
            </div>
          </li>
        </ul>
        <Mobile visible={visible} setVisible={setVisible} />
      </div>
    </header>
  );
};

export default Header;
