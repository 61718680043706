import React, { useEffect, useState } from "react";
import styles from "./GetStarted.module.scss";
import whatsapp from "assets/images/whatsapp.svg";
import viber from "assets/images/viber.svg";
import telegram from "assets/images/telegram_white.svg";
import Quiz from "components/components/Quiz/Quiz";
import { Waypoint } from "react-waypoint";

import getStartedEn from "../../../language/en/getStarted.json";
import getStartedAr from "../../../language/arab/getStarted.json";
import { GetLanguage } from "hook/GetLanguage";

const GetStarted = () => {
  const [activeAnimTitle, setActiveAnimTitle] = useState(false);
  const [activeAnimSubtitle, setActiveAnimSubtitle] = useState(false);
  const [activeAnimBlock, setActiveAnimBlock] = useState(false);
  const [activePopup, setActivePopup] = useState(false);

  const { lang } = GetLanguage();

  const [getStart, setGetStart] = useState(getStartedEn);

  const chg = () => {
    if (lang === "en") {
      setGetStart(getStartedEn);
    } else {
      setGetStart(getStartedAr);
    }
  };

  useEffect(() => {
    chg();
  });

  return (
    <div className="container">
      <div
        className={`vl-wrapper vl-item vl-item_end ${
          activeAnimTitle ? "active" : false
        }`}
      ></div>
      <section className={styles["get-started"]} id="get_started">
        <div className="title">
          <Waypoint
            onEnter={() => setActiveAnimTitle(true)}
            bottomOffset={200}
          />
          <h3
            className={`title-location ${activeAnimTitle ? "active" : false}`}
          >
            {getStart?.main}
          </h3>
          <h1
            className={`animated animatedFadeInUp ${
              activeAnimTitle ? "fadeInUp" : false
            }`}
          >
            {getStart?.Title}
          </h1>
        </div>
        <div className={`wrapper ${styles["grid-wrapper"]}`}>
          <div
            className={`subtitle ${styles.subtitle} ${styles["grid-area_paragraph"]}`}
          >
            <Waypoint
              onEnter={() => setActiveAnimSubtitle(true)}
              bottomOffset={200}
            />
            <p
              className={`animated animatedFadeInUp ${
                activeAnimSubtitle ? "fadeInUp" : false
              }`}
            >
              {getStart?.para}
            </p>
          </div>
          <Waypoint
            onEnter={() => setActiveAnimBlock(true)}
            bottomOffset={350}
          />
          <Quiz
            activeAnimBlock={activeAnimBlock}
            activePopup={activePopup}
            setActivePopup={setActivePopup}
            allQuestions={getStart?.questions}
          />
          <div
            className={`${styles._border} ${
              styles["grid-area_border"]
            } animated animatedFadeInUp ${
              activeAnimBlock ? "fadeInUp" : false
            }`}
          ></div>
          <div
            className={`${styles.contact} ${
              activePopup ? styles["z-index"] : ""
            } ${styles["grid-area_contact"]} animated animatedFadeInUp ${
              activeAnimBlock ? "fadeInUp" : false
            }`}
          >
            {/* <div className="wrapper">
              <div className="title">
                <h1>Контакты</h1>
              </div>
            </div> */}
            <div className={`wrapper ${styles.contact_grid}`}>
              {/* <address className={styles.address}>
                г. Чебоксары, ул. Проспект Ленина, 7
              </address>
              <div className={styles.phone}>
                <a className={styles.link} href="tel:+79961388203">
                  +7-996-138-82-03
                </a>
              </div> */}
              <div className={styles.email}>
                <a className={styles.link} href="mailto:durel7@mail.ru">
                  innovideuae@gmail.com
                </a>
              </div>
            </div>
            <div className={styles.social}>
              <ul className={styles.list}>
                <li className={styles.item}>
                  <a
                    className={styles.link}
                    href="https://api.whatsapp.com/send?phone=79373888107"
                    target="_blank"
                  >
                    <img
                      className={`${styles.img} ${styles["img_whatsapp"]}`}
                      src={whatsapp}
                      alt="whatsapp1"
                    />
                  </a>
                </li>
                <li className={styles.item}>
                  <a className={styles.link} href="/" target="_blank">
                    <img
                      className={`${styles.img} ${styles["img_viber"]}`}
                      src={viber}
                      alt="viber"
                    />
                  </a>
                </li>
                <li className={styles.item}>
                  <a
                    className={styles.link}
                    href="https://t.me/durel7"
                    target="_blank"
                  >
                    <img
                      className={`${styles.img} ${styles["img_telegram"]}`}
                      src={telegram}
                      alt="telegram"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GetStarted;
