import React, { useRef, useState } from "react";
import styles from "./Quiz.module.scss";
import FirstQuestion from "./FirstQuestion";
import SecondThirdQuestions from "./SecondThirdQuestions";
import { questions } from "data/QuestionsForQuiz/QuestionsForQuiz";
import QuizPopup from "../QuizPopup/QuizPopup";
import axios from "axios";
import { GetLanguage } from "hook/GetLanguage";

//this should be here

export default function Quiz({
  activeAnimBlock,
  activePopup,
  setActivePopup,
  allQuestions,
}) {
  const { lang } = GetLanguage();
  let [currentQuestion, setCurrentQuestion] = useState(0);
  let [answerForBudget, setAnswerForBudget] = useState("Бюджет не определён");
  const [choiceActiveYes, setChoiceActiveYes] = useState(false);
  const [choiceActiveNo, setChoiceActiveNo] = useState(false);
  const itemChoice = useRef(null);
  const btnRef = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [nameError, setNameError] = useState(
    lang === "en"
      ? "Please enter your name or organization"
      : "الرجاء إدخال اسمك أو مؤسستك"
  );
  const [emailError, setEmailError] = useState("Пожалуйста, укажите почту");
  const [telError, setTelError] = useState("Пожалуйста, укажите телефон");
  const [nameValid, setNameValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [telValid, setTelValid] = useState(false);
  const reEmail =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [quizAnswer, setQuizAnswer] = useState({
    type: "",
    products: [],
    budget: "",
    name: "",
    phone: "",
    email: "",
  });

  const handleButtonNextClick = async (e) => {
    e.preventDefault();
    if (choiceActiveYes || choiceActiveNo) {
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion === questions.length) {
        if (btnRef.current.innerText === "Send" || "إرسال") {
          if (name.length === 0) {
            setNameValid(true);
            setNameError(
              lang === "en"
                ? "Please enter your name or organization"
                : "الرجاء إدخال اسمك أو مؤسستك"
            );
          } else if (name.length === 1) {
            setNameValid(true);
            setNameError(
              lang === "en"
                ? "Please enter the correct name or organization"
                : "الرجاء إدخال الاسم أو المؤسسة الصحيحة"
            );
          }

          if (email.length === 0) {
            setEmailValid(true);
            setEmailError(
              lang === "en"
                ? "Please specify your email address"
                : "يرجى تحديد عنوان بريدك الإلكتروني"
            );
          } else if (!reEmail.test(email)) {
            setEmailValid(true);
            setEmailError(
              lang === "en"
                ? "Please enter the correct email address"
                : "الرجاء إدخال عنوان البريد الإلكتروني الصحيح"
            );
          }

          if (tel.length === 0) {
            setTelValid(true);
            setTelError(
              lang === "en"
                ? "Please specify the phone number"
                : "يرجى تحديد رقم الهاتف"
            );
          } else if (tel.replace(/\D/g, "").length < 4) {
            setTelValid(true);
            setTelError(
              lang === "en"
                ? "Please specify the correct phone number"
                : "يرجى تحديد رقم الهاتف الصحيح"
            );
          }
          if (
            name.length > 1 &&
            email.length > 0 &&
            reEmail.test(email) &&
            tel.length >= 4
          ) {
            quizAnswer.name = name;
            quizAnswer.email = email;
            quizAnswer.phone = tel;

            // const myData = {
            //   email: email,
            //   clientName: name,
            //   clientPhone: tel,
            // };

            // console.log(myData);

            try {
              const { data } = await axios.post(
                "https://innovide-node-backend.onrender.com/api/send-email",
                quizAnswer
              );

              console.log(data);
            } catch (error) {
              console.log(error);
            }

            setActivePopup(true);
          }
          return;
        }
      }
      setCurrentQuestion(nextQuestion);
    } else {
      const listItems = itemChoice.current.childNodes;
      for (let listItem of listItems) {
        listItem.classList.toggle(styles.anim);
      }
      setTimeout(() => {
        for (let listItem of listItems) {
          listItem.classList.toggle(styles.anim);
        }
      }, 1500);
    }
    if (currentQuestion === 1) {
      quizAnswer.products = [];
      let checkboxes = document.querySelectorAll(".checkbox_isChecked");
      let isChecked = false;
      let checkedOnce = 0;
      let answerForProduct = [];
      for (let checkbox of checkboxes) {
        console.log(checkbox.checked);
        if (checkbox.checked === false) {
          isChecked = false;
        } else {
          answerForProduct.push(checkbox.value);
          checkedOnce = 1;
        }
      }
      if (checkedOnce !== 0) {
        isChecked = true;
        quizAnswer.products = answerForProduct;
      }
      if (!isChecked) {
        setCurrentQuestion(currentQuestion);
        for (let checkbox of checkboxes) {
          // checkbox.style.transform = 'scale(1.2)'
          checkbox.nextElementSibling.classList.add("anim-scale");
          setTimeout(() => {
            checkbox.nextElementSibling.classList.remove("anim-scale");
          }, 1000);
        }
      }
    }
    if (currentQuestion === 2) {
      quizAnswer.budget = "";
      let radios = document.querySelectorAll(".radio_isChecked");
      let isChecked = false;
      let checkedOnce = 0;
      for (let radio of radios) {
        if (radio.checked === false) {
          isChecked = false;
        } else {
          checkedOnce = 1;
          quizAnswer.budget = radio.value;
        }
      }
      if (checkedOnce !== 0) {
        isChecked = true;
      }
      if (!isChecked) {
        setCurrentQuestion(currentQuestion);
        for (let radio of radios) {
          radio.nextElementSibling.classList.add("anim-scale");
          setTimeout(() => {
            radio.nextElementSibling.classList.remove("anim-scale");
          }, 1000);
        }
      }
    }
  };

  const handleButtonBackClick = () => {
    const backQuestion = currentQuestion - 1;
    if (backQuestion < 0) {
      return;
    }
    setCurrentQuestion(backQuestion);
  };

  const changeHandlerName = (e) => {
    setName(e.target.value);
    setNameValid(false);
  };

  const changeHandlerEmail = (e) => {
    setEmail(e.target.value);
    setEmailValid(false);
  };

  const changeHandlerTel = (e) => {
    setTel(e.target.value);
    setTelValid(false);
  };

  const resetQuiz = () => {
    setActivePopup(false);
    setCurrentQuestion(0);
    setChoiceActiveNo(false);
    setChoiceActiveYes(false);
    setName("");
    setEmail("");
    setTel("");
  };

  return (
    <div
      className={`${styles.quiz} ${
        styles["grid-area_quiz"]
      } animated animatedFadeIn ${activeAnimBlock ? "fadeIn" : ""}`}
    >
      {currentQuestion < 3 ? (
        <div className={styles.main}>
          <div className={styles.title}>
            <h4>{questions[currentQuestion].questionText}</h4>
          </div>
          {currentQuestion === 0 ? (
            <FirstQuestion
              questions={questions}
              currentQuestion={currentQuestion}
              choiceActiveYes={choiceActiveYes}
              setChoiceActiveYes={setChoiceActiveYes}
              choiceActiveNo={choiceActiveNo}
              setChoiceActiveNo={setChoiceActiveNo}
              itemChoice={itemChoice}
              quizAnswer={quizAnswer}
              allQuestions={allQuestions}
            />
          ) : (
            <SecondThirdQuestions
              questions={questions}
              currentQuestion={currentQuestion}
              allQuestions={allQuestions}
            />
          )}
        </div>
      ) : (
        <div className={styles.main}>
          <form
            id="hook-form"
            className={`${styles.form} ${styles[`form-contact`]}`}
          >
            {questions[currentQuestion].questionOption.map((item, idx) => {
              if (item.name === "name") {
                return (
                  <label
                    key={`label key ${idx}`}
                    className={`${styles.label} ${styles.label_block}`}
                  >
                    <span>{item.answerText}</span>
                    <input
                      style={{ borderColor: nameValid ? "red" : "revert" }}
                      className={`${styles.input} ${
                        styles[`input-${item.name}`]
                      }`}
                      type={item.type}
                      name={item.name}
                      placeholder={
                        allQuestions[currentQuestion]?.questionOption[idx]
                          .placeholder
                      }
                      value={name}
                      onChange={(e) => changeHandlerName(e)}
                    />
                    {nameValid && (
                      <div
                        className={`${styles.valid} ${styles["valid-name"]}`}
                      >
                        {nameError}
                      </div>
                    )}
                  </label>
                );
              }
              if (item.name === "email") {
                return (
                  <label
                    key={`label key ${idx}`}
                    className={`${styles.label} ${styles.label_block}`}
                  >
                    <span>{item.answerText}</span>
                    <input
                      style={{ borderColor: emailValid ? "red" : "revert" }}
                      className={`${styles.input} ${
                        styles[`input-${item.name}`]
                      }`}
                      type={item.type}
                      name={item.name}
                      placeholder={
                        allQuestions[currentQuestion]?.questionOption[idx]
                          .placeholder
                      }
                      value={email}
                      onChange={(e) => changeHandlerEmail(e)}
                    />
                    {emailValid && (
                      <div
                        className={`${styles.valid} ${styles["valid-email"]}`}
                      >
                        {emailError}
                      </div>
                    )}
                  </label>
                );
              }
              if (item.name === "tel") {
                return (
                  <label
                    key={`label key ${idx}`}
                    className={`${styles.label} ${styles.label_block}`}
                  >
                    <span>{item.answerText}</span>
                    <input
                      style={{ borderColor: telValid ? "red" : "revert" }}
                      className={`${styles.input} ${
                        styles[`input-${item.name}`]
                      }`}
                      type={item.type}
                      name={item.name}
                      placeholder={
                        allQuestions[currentQuestion]?.questionOption[idx]
                          .placeholder
                      }
                      value={tel}
                      onKeyDown={(e) => {
                        if (!/\d|\+|\-|Backspace/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => changeHandlerTel(e)}
                    />
                    {telValid && (
                      <div className={`${styles.valid} ${styles["valid-tel"]}`}>
                        {telError}
                      </div>
                    )}
                  </label>
                );
              }
            })}
          </form>
        </div>
      )}
      <div className={styles.btns}>
        <button
          onClick={() => handleButtonBackClick()}
          type="button"
          className={`${styles.btn}
                ${styles.btn_back}
                ${currentQuestion !== 0 ? styles["btn_active"] : ""}
                `}
        >
          {lang !== "en" ? "العودة" : "prev"}
        </button>
        <button
          form="hook-form"
          ref={btnRef}
          onClick={(e) => handleButtonNextClick(e)}
          type={currentQuestion < 3 ? "button" : "submit"}
          className={`${styles.btn} ${
            currentQuestion < 3 ? `${styles.btn_next}` : `${styles.btn_submit}`
          }`}
        >
          {currentQuestion < 3
            ? `${lang === "en" ? "Next" : "التالي"}`
            : `${lang === "en" ? "Send" : "إرسال"}`}
        </button>
      </div>
      <QuizPopup
        activePopup={activePopup}
        setActivePopup={setActivePopup}
        resetQuiz={resetQuiz}
      />
    </div>
  );
}
