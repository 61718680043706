import React, { useEffect, useState } from "react";
import styles from "./BurgerMenu.module.scss";
import whatsapp from "assets/images/whatsapp.svg";
import viber from "assets/images/viber.svg";
import telegram from "assets/images/telegram_white.svg";
import { menu } from "../../../data/Menu/Menu";
import { GetLanguage } from "hook/GetLanguage";

import menuEn from "../../../language/en/menu.json";
import menuArab from "../../../language/arab/menu.json";

const BurgerMenu = ({ visible, setVisible }) => {
  const goToLink = () => {
    setVisible(false);
    document.body.style.overflow = "visible";
  };

  const { lang, changeLng } = GetLanguage();
  const [myMenu, setMyMenu] = useState(menuEn);

  const chg = () => {
    if (lang === "en") {
      setMyMenu(menuEn);
    } else {
      setMyMenu(menuArab);
    }
  };

  useEffect(() => {
    chg();
  });

  return (
    <div className={`${styles["burger-menu"]} ${visible ? styles.active : ""}`}>
      <ul className={`${styles.nav}`}>
        {myMenu.map((item, idx) => (
          <li
            key={`menu item ${idx}`}
            className={styles["list-item"]}
            onClick={goToLink}
          >
            <a href={item.link} className={styles.link}>
              {item.title}
            </a>
          </li>
        ))}
        <li
          style={{ display: "flex" }}
          className={`${styles.langs} ${styles["list-item"]}`}
        >
          <div style={{ display: "flex" }}>
            <button
              style={
                lang === "en"
                  ? { backgroundColor: "#455ce9", color: "#fff" }
                  : {}
              }
              href="#"
              className="buttonEn"
              onClick={() => lang !== "en" && changeLng("en")}
            >
              EN
            </button>
            <button
              style={
                lang === "arb"
                  ? { backgroundColor: "#455ce9", color: "#fff" }
                  : {}
              }
              href="#"
              className="buttonRu"
              onClick={() => lang !== "arb" && changeLng("arb")}
            >
              AR
            </button>
          </div>
        </li>
      </ul>
      <div className={styles.social}>
        <div className={styles.whatsapp}>
          <a className={styles.link} href="" target="_blank" rel="noreferrer">
            <img src={whatsapp} alt="inst" />
          </a>
        </div>
        <div className={styles.viber}>
          <a className={styles.link} href="" target="_blank" rel="noreferrer">
            <img src={viber} alt="inst" />
          </a>
        </div>
        <div className={styles.telegram}>
          <a
            className={styles.link}
            href="https://t.me/innovide"
            target="_blank"
            rel="noreferrer"
          >
            <img src={telegram} alt="telegram" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
