import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import about from "../language/en/about.json";
import benefit from "../language/en/benefit.json";
import getStarted from "../language/en/getStarted.json";
import service from "../language/en/service.json";

import aboutAr from "../language/arab/about.json";
// import benefit from "../language/arab/benefit.json";
// import getStarted from "../language/arab/getStarted.json";
// import service from "../language/arab/service.json";

export const GetLanguage = () => {
  const [lang, setLang] = useState("en");

  const changeLng = (lg) => {
    Cookies.remove("lg");
    Cookies.set("lg", lg);
    setLang(lg);

    if (typeof window !== "undefined") {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (!Cookies.get("lg")) {
      Cookies.set("lg", "en");
      setLang("en");
    } else {
      setLang(Cookies.get("lg"));
    }
  }, []);

  return {
    lang,
    changeLng,
  };
};
