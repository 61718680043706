import React, { useEffect, useState } from "react";
import about_us from "assets/images/AboutUs/about_us.svg";
import styles from "./AboutUs.module.scss";
import { Waypoint } from "react-waypoint";

import aboutEn from "../../../language/en/about.json";
import aboutAr from "../../../language/arab/about.json";
import { GetLanguage } from "hook/GetLanguage";

const AboutUs = () => {
  const [activeAnim, setActiveAnim] = useState(false);

  const { lang } = GetLanguage();
  const [aboutData, setAboutData] = useState(aboutEn);

  const chg = () => {
    if (lang === "en") {
      setAboutData(aboutEn);
    } else {
      setAboutData(aboutAr);
    }
  };

  useEffect(() => {
    chg();
  });

  return (
    <div className="container">
      <div className={`vl-wrapper vl-item_start`}></div>
      <section
        className={`${styles["about-us"]} animated animatedFadeInUp ${
          activeAnim ? "fadeInUp" : false
        }`}
        id="about_us"
      >
        <Waypoint onEnter={() => setActiveAnim(true)} bottomOffset={200} />
        <div className="wrapper">
          <div className="title">
            <h1>
              {/* {aboutData[0]} */}
              <span className={styles.blue}>Innovide</span> — {aboutData[0]}
              <br />
              {aboutData[1]}
            </h1>
          </div>
          <div className={`subtitle ${styles.subtitle}`}>
            <p>{aboutData[2]}</p>
          </div>
        </div>
        <div className={styles.img}>
          <img src={about_us} alt="about us" />
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
