import Footer from "components/screens/Footer/Footer";
import AboutUs from "components/screens/AboutUs/AboutUs";
import Benefits from "components/screens/Benefits/Benefits";
import GetStarted from "components/screens/GetStarted/GetStarted";
import Main from "components/screens/Main/Main";
import Header from "./components/screens/Header/Header";
import OurTeam from "components/screens/OurTeam/OurTeam";
import Services from "components/screens/Services/Services";
import { useState } from "react";
import BurgerMenu from "components/components/BurgerMenu/BurgerMenu";
import { GetLanguage } from "hook/GetLanguage";

function App() {
  const [loading, setLoading] = useState(true);
  const preloader = document.getElementById("preloader");
  if (preloader) {
    setTimeout(() => {
      preloader.style.opacity = 0;
      setLoading(false);
    }, 1300);
  }
  const [visible, setVisible] = useState(false);

  // dir={lang === "arb" ? "rtl" : "ltr"}

  return (
    !loading && (
      <div className="App">
        <div className="starting">
          <Header visible={visible} setVisible={setVisible} />
          <Main />
        </div>
        <AboutUs />
        <Benefits />
        <Services />
        <GetStarted />
        <Footer />
        <BurgerMenu visible={visible} setVisible={setVisible} />
      </div>
    )
  );
}

export default App;
