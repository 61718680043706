import React, { useState, useEffect } from "react";
import styles from "./Services.module.scss";
import { Waypoint } from "react-waypoint";
import Main from "./Main";
import Mobile from "./Mobile";
import { GetLanguage } from "hook/GetLanguage";
import servicesEn from "../../../language/en/service.json";
import servicesAr from "../../../language/arab/service.json";

export default function Services() {
  const [activeAnimTitle, setActiveAnimTitle] = useState(false);
  const [activeAnimBlock, setActiveAnimBlock] = useState(false);
  const [activeAnimButton, setActiveAnimButton] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 885) {
        setMobile(false);
      } else {
        setMobile(true);
      }
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const { lang } = GetLanguage();
  const [servicesData, setServicesData] = useState(servicesEn);

  const chg = () => {
    if (lang === "en") {
      setServicesData(servicesEn);
    } else {
      setServicesData(servicesAr);
    }
  };

  useEffect(() => {
    chg();
  });

  return (
    <div className="container">
      <div
        className={`vl-wrapper vl-item ${activeAnimTitle ? "active" : ""}`}
      ></div>
      <section className={styles.services} id="services">
        <div className="title">
          <Waypoint
            onEnter={() => setActiveAnimTitle(true)}
            bottomOffset={200}
          />
          <h3 className={`title-location ${activeAnimTitle ? "active" : ""}`}>
            {servicesData?.first[0]}
          </h3>
          <h1
            className={`animated animatedFadeInUp ${
              activeAnimTitle ? "fadeInUp" : ""
            }`}
          >
            {servicesData?.first[1]}
          </h1>
        </div>
        <Waypoint onEnter={() => setActiveAnimBlock(true)} bottomOffset={200} />
        <Main
          activeAnimBlock={activeAnimBlock}
          activeAnimButton={activeAnimButton}
          setActiveAnimButton={setActiveAnimButton}
          mobile={mobile}
          servicesData={servicesData?.second}
        />
        <Mobile
          activeAnimBlock={activeAnimBlock}
          mobile={mobile}
          servicesData={servicesData?.second}
        />
      </section>
    </div>
  );
}
