import React, { useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import logo from "assets/images/logo_footer.svg";
import { menu } from "../../../data/Menu/Menu";
import telegram from "assets/images/telegram_white.svg";
import whatsapp from "assets/images/whatsapp.svg";
import viber from "assets/images/viber.svg";
import poly from "assets/docs/poly.pdf";

import menuEn from "../../../language/en/menu.json";
import menuArab from "../../../language/arab/menu.json";
import { GetLanguage } from "hook/GetLanguage";

export default function Footer() {
  const { lang } = GetLanguage();
  const [myMenu, setMyMenu] = useState(menuEn);

  const chg = () => {
    if (lang === "en") {
      setMyMenu(menuEn);
    } else {
      setMyMenu(menuArab);
    }
  };

  useEffect(() => {
    chg();
  });

  return (
    <footer className={styles.footer}>
      <div className={`wrapper ${styles["grid-wrapper"]}`}>
        <nav className={styles.nav}>
          <div className={styles.logo_footer}>
            <a className={styles.link} href="#">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <ul className={styles.menu}>
            {myMenu.map((item, idx) => (
              <li key={`menu item ${idx}`} className={styles["item"]}>
                <a href={item.link} className={styles.link}>
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.social}>
            <a
              href="https://api.whatsapp.com/send?phone=79373888107"
              target="_blank"
            >
              <img src={whatsapp} alt="whatsapp" />
            </a>
            <a href="" target="_blank">
              <img className={styles.viber} src={viber} alt="viber" />
            </a>
            <a href="https://t.me/durel7" target="_blank">
              <img src={telegram} alt="telegarm" />
            </a>
          </div>
        </nav>
        <div className={styles.info}>
          <div className="copyright">
            <p>© Copyright 2022, ООО«Innovide». All rights reserved.</p>
          </div>
          <div className="policy">
            <p>
              <a href={poly} target="_blank">
                {lang === "en"
                  ? "Personal Data Protection and Processing Policy"
                  : "سياسة حماية ومعالجة البيانات الشخصية"}
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
