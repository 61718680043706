import React, { useRef } from "react";
import styles from "./Header.module.scss";
import { GetLanguage } from "hook/GetLanguage";

const Mobile = ({ visible, setVisible }) => {
  const visibleBurgerMenu = () => {
    if (visible) {
      setVisible(false);
      document.body.style.overflow = "visible";
      return;
    }
    document.body.style.overflow = "hidden";
    setVisible(true);
  };

  //   const windowWidth = useRef(window.innerWidth);

  //   console.log("width: ", windowWidth.current);

  //   const { lang, changeLng } = GetLanguage();

  return (
    <div
      className={`${styles["burger-btn"]} ${visible ? styles.active : ""}`}
      onClick={visibleBurgerMenu}
    />
  );
};

export default Mobile;
